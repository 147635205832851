.burger {
  height: 3em;
  width: 3em;
  position: relative;
  font-size: 12px;
  cursor: pointer;
  transition: .2s all;
  -webkit-tap-highlight-color: transparent;
  transform: scale(0.79);
  z-index: 1;
  display: none;
  .burger-lines:after {
    left: 0;
    top: -1em;
  }
  .burger-lines:before {
    left: 1em;
    top: 1em;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 55px;
    width: 55px;
    top: -9.5px;
    left: -9.5px;
    background-color: var(--color-white);
    border-radius: 7.5px;
  }
  .burger-lines {
    top: 50%;
    margin-top: -0.125em;
    display: block;
    &,
    &:after,
    &:before {
      pointer-events: none;
      display: block;
      content: '';
      width: 100%;
      border-radius: 0.25em;
      background-color: var(--color-green);
      height: 0.25em;
      position: absolute;
      transform: rotate(0);
      z-index: 1;
    }
    &:after {
      left: 0;
      top: -1em;
    }
    &:before {
      left: 1em;
      top: 1em;
    }
  }
}

.burger.burger-slip {
  .burger-lines {
    &:after,
    &:before {
      width: 2em;
    }
    &,
    &:after,
    &:before {
      transition: all .2s;
    }
  }

  &.open {
    .burger-lines {
      & {
        transform: translateX(-2em);
        background-color: transparent;
      }
      &:before,
      &:after {
        left: 0.5em;
        top: 0px;
      }
      &:before {
        transform: translateX(2em) rotate(135deg);
      }
      &:after {
        transform: translateX(2em) rotate(-135deg);
      }
    }
  }
}
