.icon {
  display: inline-block;
  fill: var(--color-white);
  transition: all linear .2s;
}
.icon-phone {
  width: 20px;
  height: 20px;
}
.icon-user {
  width: 24px;
  height: 24px;
}
.icon-search {
  width: 28px;
  height: 28px;
}
.icon-question-mark {
  width: 20px;
  height: 20px;
}
.icon-document-light {
  width: 20px;
  height: 20px;
}
.icon-lawyer {
  width: 32px;
  height: 32px;
}
.icon-clock {
  width: 32px;
  height: 32px;
}
.icon-star {
  width: 28px;
  height: 28px;
}
.icon-arrow-down {
  fill: transparent;
  stroke: var(--color-white);
  width: 12px;
  height: 7px;
}
.icon-question-decor {
  width: 25px;
  height: 25px;
}
.icon-arrow {
  width: 30px;
  height: 30px;
}
.icon-pin {
  width: 27px;
  height: 27px;
}

