@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src:
      url(#{$path}.woff) format("woff"),
      url(#{$path}.woff2) format("woff2"),
      url(#{$path}.ttf) format("truetype");
    font-weight: $weight;
    font-style: $style;
  }
}
@include font-face('Open Sans', '../fonts/opensans/opensans/opensans', 400, 'normal');
@include font-face('Open Sans', '../fonts/opensans/opensansbold/opensansbold', 700, 'normal');
@include font-face('Open Sans', '../fonts/opensans/opensanssemibold/opensanssemibold', 600, 'normal');
@include font-face('Mont', '../fonts/mont/mont/Mont-Bold', 700, 'normal');
@include font-face('Mont', '../fonts/mont/montheavy/Mont-Heavy', 900, 'normal');
