:root {
  --color-white: #fff;
  --color-black: #000;
  --color-bg: #3f3f3f;
  --color-green: #17BA9B;
  --color-green-hover: #0D9D82;
  --color-blue: #0057FF;
  --color-blue-hover: #0749CB;
  --color-btn: #FF9900;
  --color-yellow: #F7D524;
  --color-gray: #A0A0A0;
  --color-text: #818181;
  --color-subtext: #6B6B6B;
  --color-red: #FF6464;
  --color-preview: #E3F7FB;
  --color-input-border: #ECECEC;
}
