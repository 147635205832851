.menu {
  &__list {
    display: flex;
    align-items: center;
  }
  &__link {
    position: relative;
    padding-bottom: 25px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 3px;
      background-color: var(--color-btn);
      transition: all linear .2s;
      left: 50%;
      transform: translateX(-50%);
      margin-right: -50%;
      width: 0;
    }
  }
  &__item {
    margin-right: 40px;
    font-weight: 600;
    position: relative;
    margin-top: 25px;
    &--icon {
      .menu__link {
        padding-right: 10px;
      }
    }

    &:hover {
      .menu__dropdown {
        transform: translateX(-50%) scaleY(1);
      }
      .menu__item-icon {
        transform: rotate(180deg);
      }
      .menu__link {
        &::before {
          width: 100%;
        }
      }
    }
  }
  &__dropdown {
    position: absolute;
    width: 815px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.89) 97.9%);
    border-radius: 0px 0px 20px 20px;
    color: var(--color-bg);
    padding: 30px 20px;
    left: calc(50% - 30px);
    transform: translateX(-50%) scaleY(0);
    margin-right: -50%;
    transition: all linear .2s;
    transform-origin: top;
    &-icon {
      width: 18px;
      height: 18px;
      fill: var(--color-bg);
    }
    &-row {
      display: flex;
    }
    &-col {
      flex: 1 1 auto;
      padding: 0 20px;
      border-right: 1px solid rgba(150, 150, 150, 0.33);
      &:last-child {
        padding-right: 0;
        border-right: none;
      }
      &:first-child {
        padding-left: 0;
      }
    }
    &-icon {
      margin-right: 10px;
      vertical-align: bottom;
    }
    &-link {
      padding: 10px;
      border-radius: 10px;
      transition: all linear .2s;
      display: block;
      &:hover {
        color: var(--color-white);
        background-color: var(--color-green);
        .menu__dropdown-icon {
          fill: var(--color-white);
        }
      }
    }
  }
}
.header {
  &::after {
    content: '';
    position: absolute;
    background-color: rgba(#214433, .8);
    bottom: 0;
    height: 100vh;
    width: 100%;
    z-index: -1;
    top: 100%;
    transition: all linear .2s;
    display: none;
  }
  &--open {
    &::after {
      display: block;
    }
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      width: 100%;
      height: 1px;
      background-color: #5DCFB9;
      z-index: 1;
    }
  }
}
