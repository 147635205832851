.difference {
  &__bg {
    padding: 100px 0;
  }

  &__row {
    margin-top: 25px;
    display: flex;
    margin-left: -30px;
  }

  &__col {
    width: calc(50% - 30px);
    margin-left: 30px;
    background: var(--color-white);
    border-radius: 50px;
    padding: 40px;
    &--shadow {
      box-shadow: 0px 0px 40px rgba(255, 255, 255, 0.7);
    }
  }
  &__list {
    padding-top: 15px;
  }
  &__item {
    display: flex;
    margin-top: 15px;
    &-text {
      width: 100%;
      max-width: 300px;
      &:first-child {
        max-width: 190px;
        margin-right: 10px;
      }
      color: var(--color-subtext);
      &--color {
        &-red {
          color: var(--color-red);
        }
        &-green {
          color: var(--color-green);
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .difference__col {
    width: calc(50% - 15px);
    margin-left: 15px;
    padding: 25px;
  }
  .difference__row {
    margin-left: -15px;
  }
}
@media (max-width: 940px) {
  .difference__item-text {
    font-size: 14px;
    line-height: 18px;
  }
  .difference__item {
    margin-top: 10px;
  }
}
@media (max-width: 820px) {
  .difference__row {
    display: block;
    margin-left: 0;
  }
  .difference__item-text {
    font-size: 16px;
    line-height: 20px;
  }
  .difference__col {
    margin-bottom: 20px;
    max-width: 530px;
    width: 100%;
    padding: 40px;
    margin-left: auto;
    margin-right: auto;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
@media (max-width: 500px) {
  .difference__bg {
    padding: 50px 0;
  }
  .difference__col {
    padding: 25px;
    border-radius: 30px;
  }
  .difference__item-text {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 350px) {
  .difference__col {
    padding: 20px 15px;
    border-radius: 20px;
  }
  .difference__item-text {
    font-size: 12px;
    line-height: 16px;
    &:last-child {
      margin-right: -18px;
    }
  }
}
