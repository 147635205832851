@mixin box($width,$height: $width) {
  width: $width;
  height: $height;
}
@mixin cover-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@mixin placeholder {
  ::placeholder {@content}
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
$units: 1px !default;
@mixin screen($res-first, $res-last: $res-first) {
  @if $res-first == $res-last {
    @media screen and (max-width: if(unitless($res-first), ($res-first - 1) *$units, $res-first)) {
      @content;
    }
  }
  @else if $res-first < $res-last {
    @media screen and (min-width: if(unitless($res-first), $res-first*$units, $res-first)) and (max-width: if(unitless($res-last), $res-last*$units, $res-last)) {
      @content;
    }
  }
}
@mixin min-screen($res) {
  @media screen and (min-width: if(unitless($res), $res*$units, $res)) {
    @content;
  }
}
