.performance {
  padding: 100px 0 40px;
  position: relative;
  z-index: 1;
  @media (max-width: 1100px) {
    padding: 100px 0 15px;
  }
  &::before,
  &::after{
    content: '';
    position: absolute;
    background: url(../images/common/circle.svg) no-repeat center/cover;
    z-index: -1;
    @media (max-width: 580px) {
      display: none;
    }
  }
  &::before {
    width: 148 / 1920 * 100%;
    padding-top: 148 / 1920 * 100%;
    left: -74 / 1920 * 100%;
    top: 0;
  }
  &::after {
    width: 305 / 1920 * 100%;
    padding-top: 305 / 1920 * 100%;
    right: -150.25 / 1920 * 100%;
    bottom: 0;
  }
  @media (max-width: 580px) {
    padding: 10px 0;
  }
  &__top {
    align-items: center;
  }
  &__slider {
    margin: -15px;
  }
  &__slide {
    padding: 15px;
  }
  &__title {
    margin-bottom: 20px;
  }
  &__box {
    padding: 40px 40px 20px;
    @media (max-width: 580px) {
      padding: 20px 20px 10px;
    }
    @media (max-width: 340px) {
      padding: 15px 15px 5px;
    }
    ul {
      list-style: none;
      li {
        font-size: 18px;
        line-height: 32px;
        margin-bottom: 15px;
        @media (max-width: 400px) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
  &__images {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    @media (max-width: 700px) {
      margin-left: -10px;
    }
  }
  &__image {
    position: relative;
    width: calc((100%  / 7) - 20px);
    margin-left: 20px;
    padding-top: 170 / 1080 * 100%;
    margin-bottom: 20px;
    @media (max-width: 900px) {
      width: calc((100%  / 5) - 20px);
    }
    @media (max-width: 700px) {
      width: calc((100%  / 4) - 10px);
      margin-left: 10px;
    }
    @media (max-width: 580px) {
      min-height: 100px;
      margin-bottom: 10px;
    }
    @media (max-width: 340px) {
      min-height: 75px;
    }
  }
}