.articles {
  padding-bottom: 50px;
  margin-top: 80px;
  &__btn {
    padding-left: 35px;
    padding-right: 35px;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));
    @media (max-width: 580px) {
      display: block;
      max-width: fit-content;
      margin: 0 auto;
    }
  }
  &__top {
    align-items: center;
    margin-bottom: 20px;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -35px;
    @media (max-width: 1050px) {
      margin-left: -10px;
    }
    @media (max-width: 700px) {
      margin-left: -5px;
    }
    @media (max-width: 580px) {
      margin-left: 0;
    }
  }
}
.article {
  margin-left: 47px;
  margin-top: 24px;
  width: calc((100%  / 3) - 47px);
  background-color: #E3FBEF;
  border-radius: 20px;
  margin-bottom: 35px;
  &__preview {
    position: relative;
    padding-top: 215 / 360 * 100%;
    min-height: 180px;
    img {
      border-radius: 20px;
    }
  }
  &__day {
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;
    color: #575757;
    font-family: 'Mont', Arial, sans-serif;
    border: 1px solid var(--color-green);
    top: -20px;
    left: -12px;
    background-color: var(--color-white);
  }
  &__content {
    padding: 25px;
    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 15px;
      @media (max-width: 650px) {
        margin-bottom: 10px;
      }
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: var(--color-black);
    }
    @media (max-width: 650px) {
      padding: 15px;
    }
  }
  @media (max-width: 1050px) {
    margin-left: 27px;
    width: calc((100%  / 3) - 27px);
  }
  @media (max-width: 1050px) {
    margin-left: 27px;
    width: calc((100%  / 2) - 27px);
  }
  @media (max-width: 700px) {
    margin-left: 17px;
    width: calc((100%  / 2) - 17px);
  }
  @media (max-width: 580px) {
    width: 100%;
    margin-left: 13px;
  }
  @media (max-width: 580px) {
    margin-bottom: 20px;
  }
}