@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/sprite";
//@include sprites($spritesheet-sprites);

html {
  font-family: 'Open Sans', Arial, serif;
  height: 100%;
  color: var(--color-bg);
}

body {
  height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  font-size: 16px;
  line-height: 22px;

  &.hidden {
    overflow: hidden;
    scrollbar-width: none;
    position: fixed;
    left: 0;
    right: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

*,
*::before,
*::after { box-sizing: border-box; }

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select { font: inherit; }

audio,
canvas,
iframe,
img,
svg,
video,
textarea { vertical-align: middle; }

textarea { resize: none; }

section { position: relative; }

input,
select,
button { outline: none; }

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
  overflow: hidden;
}

.container {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1180px;
  width: 100%;
}
a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}
button {
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
}
.textarea {
  border: none;
  resize: none;
  background-color: var(--color-white);
  display: block;
  border-radius: 10px;
  padding: 35px;
}
.textarea-default {
  width: 100%;
  padding: 20px 28px;
  box-shadow: 0px 4px 15px rgba(201, 201, 201, 0.1);
  border: 1px solid #ECECEC;
  height: 160px;
  font-size: 14px;
  line-height: 19px;
  border-radius: 10px;
  background-color: #F9F9FB;
  &:focus {
    border-color: var(--color-green);
  }
  @media (max-width: 450px) {
    padding: 15px;
  }
}
.textarea-green {
  display: block;
  border-radius: 10px;
  border: 2px solid var(--color-green);
  background-color: #FAFAFA;
  font-size: 14px;
  line-height: 19px;
  color: #9C9C9C;
  width: 100%;
  padding: 20px;
  &::placeholder {
    color: #9C9C9C;
  }
  @media (max-width: 450px) {
    padding: 15px;
  }
}
*, *:focus {
  outline: none;
}
.slick-slide {
  &:focus, a {
    outline: none;
  }
}
.day {
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-text);
  &--color {
    &-bg {
      color: var(--color-bg);
    }
  }
}
.slider-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    margin: 0 5px;
    border-radius: 50%;
    border: 1px solid #E0E0E0;
    &.slick-active {
      background-color: var(--color-green);
      border-color: var(--color-green);
    }
  }
  button {
    padding: 0;
    font-size: 0;

  }
}
.file-upload input[type="file"] {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  transform: scale(0);
}

@media (max-width: 550px) {
  .textarea {
    padding: 10px 15px;
  }
  .slider-pagination li {
    margin: 0 2.5px;
  }
}
@media (max-width: 340px) {
  .textarea {
    &::placeholder {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .day {
    font-size: 14px;
    line-height: 19px;
  }
}
//Модули
@import "modules/modules";
