.question {
  margin-bottom: 100px;
  padding-top: 150px;
  position: relative;
  z-index: 1;
  &::before {
    content: attr(data-decor);
    position: absolute;
    font-weight: 900;
    //font-size: 288px;
    font-size: 15vw;
    line-height: 280px;
    font-family: 'Mont', Arial, sans-serif;
    color: rgba(#F1F1F1, .5);
    z-index: 1;
    top: 0;
    left: -70px;
    @media (max-width: 1200px) {
      font-size: 17vw;
    }
    @media (max-width: 900px) {
      display: none;
    }
  }
  @media (max-width: 1200px) {
    padding-top: 70px;
  }
  @media (max-width: 580px) {
    padding-top: 40px;
  }
  &__inner {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--color-green);
    color: var(--color-white);
    display: flex;
    align-items: flex-start;
    padding: 40px;
    border-radius: 30px;
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      background-image: url(../images/content/question/question-decor.svg);
      background-repeat: no-repeat;
      background-position:  center;
      -webkit-background-size: cover;
      background-size: cover;
      left: -90px;
      top: 20px;
      width: 340px;
      height: 340px;
      z-index: -1;
      transform: rotate(40deg);
    }
  }
  &__title {
    font-size: 28px;
    line-height: 30px;
    position: relative;
    padding-left: 75px;
    margin-bottom: 30px;
    &::after {
      content: '';
      display: inline-block;
      width: 55px;
      height: 55px;
      border-radius: 0px 10px 10px 10px;
      background-color: var(--color-white);
      background-image: url(../images/content/slider/slider-icon.png);
      background-repeat: no-repeat;
      background-position:  center;
      -webkit-background-size: 35px 39px;
      background-size: 35px 39px;
      position: absolute;
      left: 0;
      top: 0;
      margin-top: 2px;
    }
  }
  &__textarea {
    width: 100%;
    height: 185px;
    padding: 20px 20px 20px 55px;
    background: rgba(250, 250, 250, 0.4);
    color: var(--color-white);
    border: 1px solid var(--color-white);
    &::placeholder {
      color: var(--color-white);
      font-weight: 600;
    }
  }
  &__form {
    max-width: 460px;
    width: 100%;
    &-box {
      position: relative;
      margin-bottom: 25px;
    }
    &-icon {
      position: absolute;
      top: 20px;
      left: 20px;
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__btn {
    margin-right: 15px;
    flex-shrink: 0;
  }
  &__phone {
    &-link {
      white-space: nowrap;
    }
    &-text {
      font-size: 12px;
      line-height: 16px;
    }
  }
  &__image {
    margin-top: -105px;
    margin-right: 30px;
    transform: translateY(42px);
  }
}
@media (max-width: 920px) {
  .question__btn {
    padding: 10px 25px;
  }
  .question__inner {
    padding: 20px;
  }
  .question__image {
    max-width: 330px;
    transform: translateY(47px);
    margin-right: 20px;
  }
  .question__title {
    margin-bottom: 20px;
  }
  .question__form-box {
    margin-bottom: 20px;
  }
}
@media (max-width: 840px) {
  .question__form-bottom {
    display: block;
  }
  .question__image {
    transform: translateY(20px);
    margin-top: 0;
  }
}
@media (max-width: 772px) {
  .question__image {
    position: absolute;
    max-width: 210px;
    transform: none;
    margin: 0;
    bottom: 270px;
    left: 15%;
  }
  .question__form {
    margin: 0 auto;
  }
  .question__phone {
    display: table;
    margin-left: auto;
  }
  .question__title {
    margin-bottom: 5px;
    max-width: 380px;
  }
  .question__btn {
    padding: 10px 40px;
  }
  .question__form-box {
    margin-top: 225px;
  }
  .question__btn {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .question__inner {
    &::before {
      left: -150px;
      top: -20px;
    }
  }
  .question__title::after {
    width: 45px;
    height: 45px;
    -webkit-background-size: 30px;
    background-size: 30px;
  }
  .question__title {
    padding-left: 60px;

  }
}
@media (max-width: 600px) {
  .question__form-box {
    margin-top: 229px;
  }
}
@media (max-width: 500px) {
  .question__image {
    left: 15px;
    bottom: 265px;
  }
  .question {
    margin-bottom: 50px;
  }
  .question__textarea {
    padding: 20px 20px 20px 55px;
  }
}
@media (max-width: 400px) {
  .question__inner {
    padding: 20px 10px;
  }
}
@media (max-width: 370px) {
  .question__image {
    max-width: 165px;
    left: 5px;
  }
  .question__form-box {
    margin-top: 170px;
  }
}
@media (max-width: 340px) {
  .question__image {
    max-width: 155px;
    bottom: 200px;
  }
  .question__textarea {
    height: 120px;
    padding: 10px 10px 10px 35px;
  }
  .question__form-icon {
    width: 15.79px;
    height: 15.79px;
    left: 13px;
    top: 12px;
  }
  .question__form-box {
    margin-top: 155px;
  }
  .question__inner::before {
    width: 150px;
    height: 150px;
    left: -40px;
    top: 20px;
  }
}
