.items {
  padding: 60px 0;
  @media (max-width: 992px) {
    padding: 40px 0;
  }
  @media (max-width: 772px) {
    padding: 25px 0;
  }
  &--decor {
    position: relative;
    z-index: 1;
    &::before {
      content: attr(data-decor);
      position: absolute;
      font-weight: 900;
      font-size: 15vw;
      line-height: 280px;
      font-family: 'Mont', Arial, sans-serif;
      color: rgba(#F1F1F1, .5);
      z-index: -1;
      bottom: -100px;
      left: -40px;
      @media (max-width: 1200px) {
        font-size: 17vw;
      }
      @media (max-width: 900px) {
        display: none;
      }
    }
  }
  &__title {
    @media (max-width: 580px) {
      text-align: left;
    }
  }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    padding-top: 20px;
    @media (max-width: 772px) {
      margin-left: -20px;
    }
    @media (max-width: 580px) {
      margin-left: 0;
    }
    @media (max-width: 400px) {
      padding-top: 10px;
    }
    & > * {
      width: calc(50% - 30px);
      margin-left: 30px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 0px 10px 10px 10px;
      margin-top: 20px;
      padding: 20px;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      transition: all linear .2s;
      background-color: var(--color-white);
      &:hover {
        background-color: var(--color-green);
        color: var(--color-white);
      }
      @media (max-width: 772px) {
        font-size: 16px;
        line-height: 20px;
        margin-left: 20px;
        width: calc(50% - 20px);
      }
      @media (max-width: 580px) {
        margin-left: 0;
        width: 100%;
      }
      @media (max-width: 400px) {
        margin-top: 10px;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}