.answer {
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:  center;
  padding-top: 120px;
  margin-top: -70px;
  padding-bottom: 100px;
  &::before,
  &::after{
    content: '';
    position: absolute;
    background: url(../images/common/circle.svg) no-repeat center/cover;
    z-index: -1;
    @media (max-width: 580px) {
      display: none;
    }
  }
  &::after {
    width: 386 / 1920 * 100%;
    padding-top: 386 / 1920 * 100%;
    right: -173 / 1920 * 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  &::before {
    width: 186 / 1920 * 100%;
    padding-top: 186 / 1920 * 100%;
    left: -67 / 1920 * 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  @media (max-width: 1050px) {
    padding-top: 100px;
  }
  @media (max-width: 580px) {
    margin-top: 0;
    padding-top: 20px;
  }
  &__btn {
    padding-left: 40px;
    padding-right: 40px;
    @media (max-width: 580px) {
      display: block;
      max-width: fit-content;
      margin: 0 auto;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -35px;
    @media (max-width: 1050px) {
      margin-left: -20px;
    }
    @media (max-width: 580px) {
      margin-left: 0;
    }
  }
  &-video {
    width: calc((100% / 3) - 35px);
    margin-left: 35px;
    margin-bottom: 30px;
    @media (max-width: 1050px) {
      width: calc((100% / 3) - 20px);
      margin-left: 20px;
    }
    @media (max-width: 972px) {
      width: calc((100% / 2) - 20px);
    }
    @media (max-width: 580px) {
      width: 100%;
      margin-left: 0;
    }
    &:hover {
      .answer-video__image {
        &::after {
          visibility: visible;
          opacity: 1;
        }
      }
      .answer-video__player {
        visibility: visible;
        opacity: 1;
      }
    }
    &__player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      opacity: 0;
      visibility: hidden;
      transition: all linear .2s;
    }
    &__icon {
      fill: var(--color-green);
      width: 78px;
      height: 78px;
    }
    &__image {
      position: relative;
      padding-top: 215 / 356 * 100%;
      margin-bottom: 30px;
      img {
        border-radius: 20px;
      }
      &::before {
        content: '';
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg width='192' height='1' viewBox='0 0 192 1' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline opacity='0.5' x1='192' y1='0.5' y2='0.5' stroke='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='192' y1='0' x2='0' y2='3.8491e-09' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2317BA9B' stop-opacity='0'/%3E%3Cstop offset='0.546875' stop-color='%2317BA9B'/%3E%3Cstop offset='1' stop-color='%2317BA9B' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        width: 192px;
        height: 1px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position:  center;
        left: 0;
        bottom: -20px;
      }
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(#fff, .7);
        z-index: 1;
        top: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
        transition: all linear .2s;
      }
    }
    &__content {
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      color: var(--color-black);
    }
  }

}