.partner{
  padding-bottom: 100px;
  border-bottom: 1px solid rgba(#000, .2);
  &__info {
    background-color: var(--color-green);
    border-radius: 30px;
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
  &__title {
    margin-bottom: 30px;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -75px;
  }
  &__col {
    width: calc(25% - 75px);
    text-align: center;
    margin-left: 75px;
    margin-bottom: 25px;
    &-image {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      min-height: 160px;
      object-fit: contain;
    }
    &-text {
      margin: auto auto 0;
      display: block;
      max-width: 209px;
    }
  }
}


@media (max-width: 1110px) {
  .partner__col {
    margin-left: 45px;
    width: calc(25% - 45px);
  }
  .partner__row {
    margin-left: -45px;
  }
}
@media (max-width: 1030px) {
  .partner__col {
    margin-left: 20px;
    width: calc(25% - 20px);
  }
  .partner__row {
    margin-left: -25px;
  }
}
@media (max-width: 992px) {
  .main-info__logo {
    margin-right: 15px;
  }
  .main-info__title {
    margin-right: 15px;
  }
  .main-info__phone {
    margin-right: 15px;
  }
  .main-info__btn {
    padding: 10px 30px;
  }
  .main-info__inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 950px) {
  .partner__col {
    width: calc(33% - 20px);
  }
}
@media (max-width: 860px) {
  .main-info {
    &__inner {
      display: block;
      max-width: 330px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    &__col {
      margin-bottom: 15px;
      text-align: left;
    }
    &__btn {
      margin-top: 20px;
    }
    &__phone {
      margin-right: 0;
    }
  }
}
@media (max-width: 650px) {
  .partner__col {
    width: calc(50% - 20px);
  }
}
@media (max-width: 500px) {
  .main-info__inner {
    padding: 15px;
  }
  .main-info {
    border-radius: 10px;
  }
  .main-info__btn {
    font-size: 14px;
    line-height: 16px;
  }
  .partner {
    padding-bottom: 50px;
  }
}
@media (max-width: 400px) {
  .partner__col-image {
    width: 89px;
    min-height: 88px;
  }
  .partner__col-text {
    font-size: 14px;
    line-height: 19px;
  }
}
