.formalized {
  padding-top: 50px;
  &__search {
    position: relative;
    margin-bottom: 25px;
    padding-top: 7.5px;
    &-input {
      border: none;
      border-bottom: 1px solid #17BA9B;
      color: #000;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      padding: 0 0 5px;
      display: block;
      width: 100%;
      &::placeholder {
        color: rgba(23, 186, 155, 0.5);
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    }
    &-btn {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      transform: rotateY(
                      180deg
      );
    }
    &-icon {
      fill: var(--color-green);
    }
  }
  &__info {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  &__price {
    min-width: 100px;
  }
  &__day {
    min-width: 87px;
  }
  &__price,
  &__day {
    margin-right: 25px;
    text-align: center;
    @media (max-width: 1000px) {
      margin-right: 10px;
    }
    @media (max-width: 800px) {
      text-align: left;
      margin-bottom: 15px;
      margin-right: 50px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__slide {
    padding: 20px;
  }
  &__slider {
    margin: 0 -20px;
    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      z-index: 3;
      height: 25px;
      width: 100%;
      background-color: #fff;
      @media (max-width: 800px) {

      }
    }
    .slick-list {
      margin-top: -10px;
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin: -5px 0 -20px;
    @media (max-width: 1000px) {
      padding: 13px 20px;
    }
    @media (max-width: 800px) {
      display: block;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: 400px) {
      padding: 15px;
    }
    & > * {
      flex: 1 1 auto;
    }
  }
  &__link {
    padding: 11px 20px;
    min-width: 220px;
    @media (max-width: 900px) {
      min-width: 150px;
    }
    @media (max-width: 800px) {
      max-width: 250px;
      width: 100%;
    }
    @media (max-width: 500px) {
      font-size: 14px;
      line-height: 19px;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 23px;
    margin-right: 20px;
    max-width: 600px;
    width: 100%;
    @media (max-width: 900px) {
      margin-right: 10px;
    }
    @media (max-width: 800px) {
      margin-bottom: 15px;
    }
    @media (max-width: 500px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}