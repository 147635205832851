.order {
  overflow: hidden;

  &__bg {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    padding-bottom: 30px;
    padding-top: 175px;
    @media (max-width: 972px) {
      padding-top: 110px;
      background-position: center;
    }
    @media (max-width: 830px) {
      margin-top: -45px;
    }
    @media (max-width: 500px) {
      margin-top: -90px;
      padding-top: 150px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 462 / 1920 * 100%;
      padding-top: 462 / 1920 * 100%;
      left: -231 / 1920 * 100%;
      top: 410 / 1920 * 100%;
      background-image: url(../images/common/circle.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      z-index: -1;
      @media (max-width: 580px) {
        display: none;
      }
    }
  }

  &__decor {
    position: absolute;
    right: 60px;
    bottom: 0;
    z-index: -1;
    @media (max-width: 1500px) {
      opacity: .5;
      right: 20px;
      max-width: 100%;
    }
    @media (max-width: 580px) {
      display: none;
    }
  }

  &-form {
    padding: 40px 130px;
    @media (max-width: 1200px) {
      padding: 40px;
      max-width: 950px;
      margin: 0 auto;
    }
    @media (max-width: 840px) {
      max-width: 740px
    }
    @media (max-width: 772px) {
      padding: 40px 30px;
      text-align: center;
    }

    &__top {
      text-align: center;
    }

    &__title {
      margin-bottom: 10px;
      @media (max-width: 500px) {
        margin-bottom: 5px;
      }
    }

    &__subtitle {
      margin-bottom: 20px;
      @media (max-width: 772px) {
        font-size: 16px;
        line-height: 20px;
      }
      @media (max-width: 500px) {
        font-size: 14px;
        line-height: 17px;
      }
    }

    &__row {
      margin-bottom: 30px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-left: -50px;
      justify-content: space-between;
      @media (max-width: 972px) {
        margin-left: -25px;
      }
      @media (max-width: 772px) {
        margin-left: 0;
        display: block;
      }

      & > * {
        margin-left: 50px;
        @media (max-width: 972px) {
          margin-left: 25px;
        }
        @media (max-width: 772px) {
          margin-left: 0;
        }
      }
    }

    &__faild {
      width: 100%;
      margin-top: 20px;
    }

    &__file {
      padding-top: 30px;
      padding-bottom: 60px;
      @media (max-width: 772px) {
        padding-bottom: 40px;
        padding-top: 10px;
      }
    }

    &__control {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 15px;
      @media (max-width: 772px) {
        justify-content: center;
      }
      @media (max-width: 500px) {
        flex-wrap: wrap;
        margin-bottom: 10px;
        justify-content: flex-start;
        span {
          display: block;
          width: 100%;
          margin-bottom: 10px;
          text-align: left;
        }
      }

      .input {
        max-width: 150px;
        width: 100%;
        margin: 0 15px;
        @media (max-width: 500px) {
          margin-left: 0;
          margin-right: auto;
          max-width: calc(100% - 45px);
        }
      }
    }

    &__btn {
      display: block;
      max-width: fit-content;
      margin: 10px auto 0;
      min-width: 260px;
      @media (max-width: 500px) {
        font-size: 18px;
        line-height: 25px;
      }
      @media (max-width: 400px) {
        min-width: 100%;
      }
    }

    &__col {
      max-width: calc(50% - 50px);
      @media (max-width: 972px) {
        max-width: calc(50% - 25px);
      }
      @media (max-width: 772px) {
        max-width: 100%;
        width: 100%;
      }
      @media (max-width: 500px) {
        margin-bottom: 20px;
      }
    }

    &__prompt {
      font-size: 11px;
      line-height: 15px;
      color: rgba(63, 63, 63, 0.7);
      display: block;
      @media (max-width: 500px) {
        text-align: left;
      }
    }
  }
}