.question__title {
  font-size: 28px;
  line-height: 30px;
}
.title {
  font-family: 'Mont', "Open Sans", sans-serif;
  font-weight: 900;
  display: block;
  span {
    color: var(--color-green);
  }
  &--color {
    &-white {
      color: var(--color-white);
    }
    &-bg {
      color: var(--color-bg);
    }
    &-gray {
      color: var(--color-gray);
    }
    &-green {
      color: var(--color-green);
    }
  }
  &--align {
    &-center {
      text-align: center;
    }
  }
  &--size {
    &-big {
      font-size: 45px;
      line-height: 60px;
    }
    &-small {
      font-size: 36px;
      line-height: 40px;
    }
    &-tiny {
      font-size: 20px;
      line-height: 26px;
    }
    &-medium {
      font-size: 24px;
      line-height: 31px;
    }
  }
}
@media (max-width: 1100px) {
  .title {
    &--size {
      &-big {
        font-size: 35px;
        line-height: 50px;
      }
      &-small {
        font-size: 30px;
        line-height: 36px;
      }
      &-medium {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}
@media (max-width: 922px) {
  .title {
    &--size {
      &-big {
        font-size: 30px;
        line-height: 45px;
      }
      &-small {
        font-size: 26px;
        line-height: 32px;
      }
      &-tiny {
        font-size: 18px;
        line-height: 24px;
      }

    }
  }
}
@media (max-width: 772px) {
  .title {
    &--size {
      &-big {
        font-size: 26px;
        line-height: 30px;
      }
      &-small {
        font-size: 24px;
        line-height: 30px;
      }
      &-medium {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
}
@media (max-width: 600px) {
  .title {
    &--size {
      &-big {
        font-size: 22px;
        line-height: 24px;
      }
      &-small {
        font-size: 22px;
        line-height: 28px;
      }
      &-tiny {
        font-size: 16px;
        line-height: 20px;
      }
      &-medium {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}
@media (max-width: 340px) {
  .title {
    &--size {
      &-big {
        font-size: 20px;
        line-height: 22px;
      }
      &-tiny {
        font-size: 14px;
        line-height: 18px;
      }
      &-medium {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
