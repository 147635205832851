.consult-chat {
  overflow: hidden;
  margin-top: -145px;
  color: var(--color-white);
  @media (max-width: 880px) {
    margin-top: 0;
  }
  @media (max-width: 580px) {
    margin-top: 60px;
  }
  &__bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -40% 100%;
    position: relative;
    z-index: 1;
    padding-bottom: 30px;
    padding-top: 175px;
    &::before {
      content: '';
      position: absolute;
      width: 462 / 1920 * 100%;
      padding-top: 462 / 1920 * 100%;
      left: -231 / 1920 * 100%;
      top: 270 / 1920 * 100%;
      background-image: url(../images/common/circle.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position:  center;
      z-index: -1;
      @media (max-width: 580px) {
        display: none;
      }
    }
    @media (max-width: 1750px) {
      background-position: -10% 100%;
    }
    @media (max-width: 1400px) {
      background-position: 0% 100%;
    }
    @media (max-width: 880px) {
      background-position: 87% 100%;
      padding-top: 65px;
      padding-bottom: 90px;
    }
    @media (max-width: 360px) {
      margin-bottom: -30px;
    }
  }
  &__decor {
    position: absolute;
    right: 60px;
    bottom: 0;
    z-index: -1;
    @media (max-width: 1500px) {
      opacity: .5;
      right: 20px;
      max-width: 100%;
    }
    @media (max-width: 580px) {
      display: none;
    }
  }
  &__items {
    display: flex;
    max-width: 1065px;
    margin-left: -70px;
    margin-bottom: 70px;
    @media (max-width: 1150px) {
      margin-left: -40px;
      justify-content: center;
      margin-bottom: 40px;
    }
    @media (max-width: 880px) {
      flex-direction: column;
      align-items: center;
      margin-left: 0;
      margin-bottom: 90px;
    }
  }
  &__item {
    margin-left: 100px;
    width: 33.3333333%;
    text-align: center;
    border: 1px solid #DEDEDE;
    border-radius: 0px 50px 50px 50px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
    background-color: #fff;
    padding: 20px;
    color: #575757;
    @media (max-width: 1150px) {
      margin-left: 50px;
      max-width: 270px;
      width: 100%;
    }
    @media (max-width: 880px) {
      margin-left: 0;
      margin-bottom: 20px;
    }
    span {
      font-size: 16px;
      line-height: 20px;
      display: block;
      font-weight: 600;
      margin-top: 5px;
    }
  }
  &__container {
    max-width: 1060px;
    margin: 0 auto;
  }
  &__number {
    display: block;
    font-weight: 600;
    font-size: 72px;
    line-height: 55px;
    color: var(--color-green);
    white-space: nowrap;
    font-family: 'Mont', "Open Sans", sans-serif;
  }
  &__content {
    max-width: 700px;
    margin-left: auto;
    margin-right: 70px;
    @media (max-width: 1150px) {
      max-width: 770 / 1060 * 100%;
      margin-bottom: 120px;
      margin-right: 30px;
    }
    @media (max-width: 880px) {
      max-width: 540px;
      margin: 0 auto;
    }
    p {
      font-size: 16px;
      line-height: 27px;
    }
  }
  &__btn {
    min-width: 260px;
    display: block;
    max-width: fit-content;
    margin: 25px auto 0;
  }
  &__box {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    @media (max-width: 880px) {
      flex-direction: column-reverse;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 50px;
    }
  }
  &__form {
    padding: 50px 70px  25px 70px;
    color: var(--color-black);
    width: 785 / 1060 * 100%;
    margin-left: 15px;
    @media (max-width: 1150px) {
      padding: 25px;
    }
    @media (max-width: 880px) {
      margin-left: 0;
      max-width: 750px;
      width: 100%;
      margin-bottom: 60px;
    }
    @media (max-width: 550px) {
      padding: 10px 10px 25px 10px;
    }
    @media (max-width: 340px) {
      padding: 5px 5px 25px 5px;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    @media (max-width: 580px) {
      flex-wrap: wrap;
    }
    &-content {
      @media (max-width: 580px) {
        width: calc(100% - 97px)
      }
    }
    &-pic {
      margin-right: 35px;
      flex-shrink: 0;
      max-width: 87px;
      border-radius: 50%;
      border: 2px solid var(--color-green);
      overflow: hidden;
      @media (max-width: 580px) {
        margin-right: 10px;
      }
    }
    b {
      font-size: 16px;
      color: var(--color-green);
    }
    p {
      &:not(:first-child) {
        margin-top: 5px;
      }
    }
  }
  &__textarea {
    box-shadow: 0px 4px 15px rgba(201, 201, 201, 0.1);
    background-color: #FAFAFA;
    width: 100%;
    margin-bottom: 25px;
    padding: 18px 15px 18px 45px;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M8.08372 2.1907L10.7282 4.83517L4.03427 11.5291L1.39128 8.88464L8.08372 2.1907ZM12.7349 1.55291L11.5555 0.37357C11.0998 -0.0822055 10.3597 -0.0822055 9.90235 0.37357L8.77265 1.50326L11.4171 4.14776L12.7349 2.83003C13.0884 2.47651 13.0884 1.90641 12.7349 1.55291ZM0.007359 12.6017C-0.0407674 12.8182 0.154786 13.0123 0.371404 12.9597L3.31825 12.2452L0.675254 9.60069L0.007359 12.6017Z' fill='%23313131' fill-opacity='0.4'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='13' height='13' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: 13px 13px;
    background-repeat: no-repeat;
    background-position:  15px 23px;
    min-height: 125px;
    @media (max-width: 550px) {
      padding: 18px 15px 18px 45px;
    }
  }
  &__text {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    margin-top: 30px;
    a {
      color: var(--color-green);
    }
    @media (max-width: 400px) {
      padding: 0 5px;
    }
  }
  &__statistics {

  }
  &__statistic {
    text-align: center;
    margin-top: 65px;
    position: relative;
    z-index: 1;
    &:first-child {
      margin-top: 30px;
    }

    strong {
      font-size: 72px;
      line-height: 60px;
      font-family: 'Mont', Arial, sans-serif;
      color: var(--color-green);
      display: inline-flex;
      align-items: flex-end;
      justify-content: center;
      &::after {
        content: attr(data-separator);
        font-size: 18px;
        line-height: 23px;
      }
    }
    &--small {
      strong {
        &::after {
          content: attr(data-separator);
          font-size: inherit;
          line-height: inherit;
        }
      }
    }
    & > span {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      display: block;
      margin-top: 5px;
      font-family: 'Mont', Arial, sans-serif;
      color: #575757;
    }
    .icon {
      fill: var(--color-green);
      width: 70px;
      height: 70px;
      position: absolute;
      z-index: -1;
      opacity: .1;
      left: 50%;
      transform: translateX(-50%);
      right: -50%;
      top: -40px;
    }
  }
}