.hidden--loader {
  overflow: hidden;
  scrollbar-width: none;
  position: fixed;
  left: 0;
  right: 0;
  &::-webkit-scrollbar {
    display: none;
  }
}
.loader{
  min-height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  &__items {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
  }
  &__circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: var(--color-green);
    border-radius: 50%;
    animation: loading 1.5s cubic-bezier(.8, .5, .2, 1.4) infinite;
    transform-origin: bottom center;
    position: relative;
    &--1 {
      animation-delay: 0.1s;
    }
    &--2 {
      animation-delay: 0.2s;
    }
    &--3 {
      animation-delay: 0.3s;
    }
    &--4 {
      animation-delay: 0.4s;
    }
    &--5 {
      animation-delay: 0.5s;
    }
    &--6 {
      animation-delay: 0.6s;
    }
    &--7 {
      animation-delay: 0.7s;
    }
    &--8 {
      animation-delay: 0.8s;
    }
  }
}
@keyframes loading{
  0%{
    transform: translateY(0px);
    background-color: var(--color-green);
  }
  50%{
    transform: translateY(50px);
    background-color: var(--color-blue);
  }
  100%{
    transform: translateY(0px);
    background-color: var(--color-green);
  }
}
