.pledge-agreement {
  margin-top: 72px;
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative;
  z-index: 1;
  &::before,
  &::after {
    content: '';
    position: absolute;
    background: url(../images/common/circle.svg) no-repeat center/cover;
    z-index: -1;
  }
  &::after {
    width: 386 / 1920 * 100%;
    padding-top: 386 / 1920 * 100%;
    right: -140 / 1920 * 100%;
    top: -195  / 555 * 100%;
    @media (max-width: 1280px) {
      top: -100  / 555 * 100%;
    }
    @media (max-width: 580px) {
      top: -60  / 580 * 100%;
    }
    @media (max-width: 400px) {
      top: -50  / 640 * 100%;
    }
  }
  &::before {
    width: 186 / 1920 * 100%;
    padding-top: 186 / 1920 * 100%;
    left: -90 / 1920 * 100%;
    top: 230 / 555 * 100%;
    @media (max-width: 1280px) {
      display: none;
    }
  }
  @media (max-width: 972px) {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  @media (max-width: 500px) {
    padding-top: 30px;
    margin-top: 50px;
  }
  &__title {
    margin-bottom: 10px;
  }
  &__content {
    margin-bottom: 60px;
    @media (max-width: 580px) {
      margin-bottom: 30px;
    }
  }
  &__form {
    display: flex;
    align-items: flex-start;
    @media (max-width: 580px) {
      display: block;
    }
  }
  &__slider {
    display: flex;
    width: 680 / 1140 * 100%;
    margin-right: 45px;
    @media (max-width: 900px) {
      display: block;
      max-width: 400px;
      width: 100%;
      margin-right: 20px;
    }
    @media (max-width: 580px) {
      max-width: 100%;
      margin-bottom: 40px;
      margin-right: 0;
    }
  }
  &__subtitle {
    display: block;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 25px;
    color: var(--color-green);
    @media (max-width: 972px) {
      font-size: 16px;
      line-height: 22px;
    }
    @media (max-width: 900px) {
      margin-bottom: 15px;
    }
    @media (max-width: 580px) {
      margin-bottom: 25px;
    }
    @media (max-width: 500px) {
      font-size: 14px;
      line-height: 19px;
    }
  }
  &__input {
    border: none !important;
  }
  &__control {
    margin-right: 50px;
    width: 50%;
    @media (max-width: 972px) {
      margin-right: 30px;
    }
    @media (max-width: 900px) {
      margin-bottom: 25px;
      width: 100%;
      margin-right: 0;
    }
    @media (max-width: 580px) {
      margin-bottom: 30px;
    }
    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
  &__box {
    width: 460 / 1140 * 100%;
    @media (max-width: 900px) {
      max-width: 400px;
      width: 100%;
    }
    @media (max-width: 580px) {
      max-width: 100%;
    }
  }
  &__textarea {
    min-height: 145px;
    margin-bottom: 30px;
  }
  &__btn {
    display: block;
    max-width: fit-content;
    margin-left: auto;
    min-width: 245px;
    padding: 10px 25px;
    @media (max-width: 400px) {
      min-width: 100%;
    }
  }
}